import { toRefs, reactive, computed } from 'vue'

import useStorage from '@/compositions/useStorage'
import api from '@/lib/api'
import GtmHelper from '@/helpers/GtmHelper'

const STORAGE_NAME = 'catalogue'
const getDefaultData = () => ({
  product: [],
  recipe: [],
  businesscase: []
})

let defaultTheme = ''
let defaultColor = ''

const getDefaultFormData = () => ({
  name: null,
  description: null,
  theme: defaultTheme,
  color: defaultColor,
  additionalInfoValue: false,
  relatedContentValue: false,
  contactInfoName: '',
  contactInfoEmail: '',
  contactInfoPhone: '',
  showArlaProBranding: true,
  showRecipeNutritionData: true,
  showSustainableDietVisuals: false, // TODO: revert it back to false,
  relatedContentTitle: '',
  relatedContentDescription: '',
  relatedContentLink: null,
  termsAccepted: false
})

const state = reactive({
  catalogueId: null,
  catalogueToDelete: null,
  isCataloguePage: false,
  catalogueTranslations: {},
  catalogueCardTranslations: {},
  recaptchaKey: '',
  formData: getDefaultFormData(),
  colors: [],
  themes: [],
  isSet: false
})

const storage = useStorage(STORAGE_NAME)
if (!storage.value || !Object.keys(storage.value).length) {
  storage.value = getDefaultData()
}

const cartSum = computed(() => Object.values(storage.value)
  .reduce((sum, ids) => (sum + ids.length), 0))

const modalName = 'catalogue-modal'
const catalogueDeleteModalName = 'catalogue-delete-modal'

export default () => {
  const getCatalogueIdInUrl = () => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('id') || null
  }

  const submitCatalogue = (data, token, trackingData) => {
    if (state.catalogueId) {
      GtmHelper.catalogueUpdate(trackingData)
      return api.editCatalogue(data, token, state.catalogueId)
    }

    GtmHelper.catalogueCreate(trackingData)
    return api.submitCatalogue(data, token)
  }

  const setCataloguePage = ({
    translations,
    recaptchaKey,
    colors,
    themes
  }) => {
    state.isCataloguePage = true
    state.catalogueTranslations = translations
    state.recaptchaKey = recaptchaKey
    state.colors = colors
    state.themes = themes
    const catalogueId = getCatalogueIdInUrl()
    if (catalogueId) {
      state.catalogueId = catalogueId
      api.getCatalogue(catalogueId)
        .then(({
          data: {
            formData,
            products: product,
            recipes: recipe,
            businessCases: businesscase
          }
        }) => {
          Object.assign(state.formData, formData)
          Object.assign(storage.value, {
            product,
            recipe,
            businesscase
          })
        }).catch((error) => {
          console.error(error)
        }).then(() => {
          if (colors.length && !state.formData.color) {
            [state.formData.color] = colors
          }
          if (themes.length && !state.formData.theme) {
            state.formData.theme = themes[0].value
          }
          state.isSet = true
        })
    } else {
      if (colors.length) {
        [defaultColor] = colors
        state.formData.color = defaultColor
      }
      if (themes.length) {
        defaultTheme = themes[0].value
        state.formData.theme = defaultTheme
      }
      state.isSet = true
    }
  }

  const setCatalogueCardTranslations = (translations) => {
    state.catalogueCardTranslations = translations
  }

  const getAmountAddedToCatalogue = (id) => {
    const itemIndex = storage.value.find((item) => item.id === id)

    return itemIndex ? itemIndex.amount : null
  }

  const addToCatalogue = ({
    id, type, name
  }, trackEvent = true) => {
    const itemIndex = storage.value[type].findIndex((itemId) => itemId === id)
    if (itemIndex === -1) {
      storage.value[type].push(id)
      if (trackEvent) {
        GtmHelper.addToCatalogue({ itemType: type, itemName: name })
      }
    }
  }

  const removeFromCatalogue = ({ id, type, name }) => {
    const itemIndex = storage.value[type].findIndex((itemId) => itemId === id)
    if (itemIndex !== -1) {
      storage.value[type].splice(itemIndex, 1)
      GtmHelper.removeFromCatalogue({ itemType: type, itemName: name })
    }
  }

  const clearCatalogue = () => {
    GtmHelper.clearCatalogue(cartSum.value)
    storage.value = getDefaultData()
  }

  const revertCatalogueToDefault = () => {
    state.formData = getDefaultFormData()
    storage.value = getDefaultData()
  }

  const addAllToCatalogue = ({ items, type }) => {
    items.forEach((item) => {
      const { id, name } = item
      addToCatalogue({
        id, type, name
      }, false)
    })
    GtmHelper.catalogueAddAll(items.length)
  }

  const deleteCatalogue = (id) => api.deleteCatalogue(id)
  const isChecked = ({ id, type }) => {
    const itemIndex = storage.value[type].findIndex((itemId) => itemId === id)

    return itemIndex !== -1
  }

  const setCatalogueToDelete = (catalogue) => {
    state.catalogueToDelete = catalogue
  }

  return {
    ...toRefs(state),
    items: storage,
    cartSum,
    modalName,
    catalogueDeleteModalName,

    isChecked,
    getAmountAddedToCatalogue,
    addToCatalogue,
    removeFromCatalogue,
    addAllToCatalogue,
    clearCatalogue,
    setCataloguePage,
    submitCatalogue,
    setCatalogueCardTranslations,
    getCatalogueIdInUrl,
    deleteCatalogue,
    setCatalogueToDelete,
    revertCatalogueToDefault
  }
}
